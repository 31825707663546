/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import Hero from 'components/shared/Hero'
import NewsOverview from 'components/shared/NewsOverview'
import Blog from 'components/related/Blog'
import Blogik, {
  BlogConsumer,
  BlogPagination,
  BlogButton,
  BlogFilter,
} from 'components/shared/Blogik'
import ParseContent from 'components/shared/ParseContent'
import ButtonSlide from 'components/elements/ButtonSlide'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...NewsFrag
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, acf, yoast_meta: yoast, wordpress_id },
  },
}) => {
  const defaultFilter = {
    2538: [11],
    2554: [12],
    2553: [13],
    21737: [19],
  }
  const defaultSelectedFilters = defaultFilter[wordpress_id] || false

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{
          url:
            acf.banner.image.localFile &&
            acf.banner.image.localFile.childImageSharp.openGraph.src,
        }}
      />

      <Hero className="mb-lg-5" left content={acf.banner} />

      {/* <NewsOverview className="pt-5" /> */}

      <section className="container pt-5">
        <Blogik
          settings={{
            id: 'latest-news',
            limit: 100,
            postIds: [],
          }}
          defaultSelectedFilters={defaultSelectedFilters}
        >
          <BlogConsumer>
            {({ hasPosts, posts, showMoreButton, showLessButton }) => (
              <div>
                {defaultSelectedFilters === false && <BlogFilters />}

                {hasPosts && (
                  <>
                    <Blog posts={posts} />
                  </>
                )}

                {!hasPosts && (
                  <>
                    <ParseContent content="Geen berichten gevonden met deze filter" />
                  </>
                )}

                <div className="text-center mt-5">
                  {showMoreButton && <BlogButton>Meer tonen</BlogButton>}
                  {showLessButton && (
                    <BlogButton increment={false}>Minder tonen</BlogButton>
                  )}
                </div>
              </div>
            )}
          </BlogConsumer>
        </Blogik>
      </section>
    </Layout>
  )
}

const StyledBlogFilter = styled(BlogFilter)`
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 30%);
  height: 40px;
  padding: 0 20px;
  position: relative;

  &[data-active='1'] {
    background: linear-gradient(
      90deg,
      rgba(114, 57, 149, 1) 0%,
      rgba(11, 189, 209, 1) 100%
    );

    & span {
      color: #fff;
      font-weight: bold;
    }
  }
`

const BlogFilters = () => (
  <BlogConsumer>
    {({ categories, isSelected }) => (
      <div className="container d-flex mb-5">
        <div className="col-lg-12 text-center">
          {categories.map((category) => (
            <StyledBlogFilter
              key={category.node.wordpress_id}
              id={category.node.wordpress_id}
              selected={isSelected(category.node.wordpress_id)}
              className="mr-3 mb-3 mb-lg-0"
            >
              <span>{category.node.name}</span>
            </StyledBlogFilter>
          ))}
        </div>
      </div>
    )}
  </BlogConsumer>
)

export default PageTemplate
